export default [
  {
    path: '/index',
    name: 'home',
    redirect: '/index/home',
    component: () => import('../../pages/home/views/index.vue'),
    children: [
      {
        path: '/index/home',
        name: 'indexHome',
        // component: () => import('../../pages/home/views/home.vue')
        component: r => require.ensure([], function (require) {
          r(require('@/pages/home/views/home'))
        }, 'home')
      },
      {
        path: '/index/product',
        name: 'indexProduct',
        // component: () => import('../../pages/home/views/product.vue')
        component: r => require.ensure([], function (require) {
          r(require('@/pages/home/views/product'))
        }, 'product')
      },
      {
        path: '/index/productMore',
        name: 'indexProductMore',
        // component: () => import('../../pages/home/views/productMore.vue')
        component: r => require.ensure([], function (require) {
          r(require('@/pages/home/views/productMore'))
        }, 'productMore')
      },
      {
        path: '/index/aboutUs',
        name: 'indexAboutUs',
        // component: () => import('../../pages/home/views/aboutUs.vue')
        component: r => require.ensure([], function (require) {
          r(require('@/pages/home/views/aboutUs'))
        }, 'aboutUs')
      },
      {
        path: '/index/joinUs',
        name: 'indexJoinUs',
        // component: () => import('../../pages/home/views/joinUs.vue')
        component: r => require.ensure([], function (require) {
          r(require('@/pages/home/views/joinUs'))
        }, 'joinUs')
      },
      {
        path: '/index/postingInformationDetail',
        name: 'indexPostingInformationDetail',
        // component: () => import('../../pages/home/views/postingInformationDetail.vue')
        component: r => require.ensure([], function (require) {
          r(require('@/pages/home/views/postingInformationDetail'))
        }, 'postingInformationDetail')
      },
      {
        path: '/index/newsList',
        name: 'indexNewsList',
        // component: () => import('../../pages/home/views/newsList.vue')
        component: r => require.ensure([], function (require) {
          r(require('@/pages/home/views/newsList'))
        }, 'newsList')
      }
    ]
  }
]
