import Vue from 'vue'
import Router from 'vue-router'
import home from './modules/home'
import admin from './modules/admin'
Vue.use(Router)
const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}
export default new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/index/home',
      // component: () => import('../pages/index/views/index.vue'),
      component: r => require.ensure([], function (require) {
        r(require('@/pages/index/views/index'))
      }, 'index'),
      children: [
        ...home,
        ...admin
      ]
    }
  ]
})
