<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss" scoped>
</style>

<style >
body {
  font-family: '思源黑体';
}
</style>
