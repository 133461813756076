import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css/normalize.css'
import './assets/css/common.scss'
import './assets/font/font.css'
import ElementUI from 'element-ui'
import App from './App.vue'
import router from './router'
import request from './utils/request'
import api from './api/index'

Vue.config.productionTip = false

Vue.use(ElementUI)

Vue.prototype.$request = request
Vue.prototype.$api = api

router.beforeEach((to, from, next) => {
  console.log(123)
  next()
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
