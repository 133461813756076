import admin from './modules/admin'
import lawGoverment from './modules/lawGoverment'
import rotatingImage from './modules/rotatingImage'
import postingInformation from './modules/postingInformation'
import agriculturalDataInquiry from './modules/agriculturalDataInquiry'
import communicationConsulting from './modules/communicationConsulting'
import product from './modules/product'
import productType from './modules/productType'
import jobRecruitment from './modules/jobRecruitment'
import strategicCooperation from './modules/strategicCooperation'
import companyProfile from './modules/companyProfile'
import companyQualification from './modules/companyQualification'
import companyCulture from './modules/companyCulture'
import accessLog from './modules/accessLog'
import companyHonors from './modules/companyHonors'
export default {
  ...admin,
  ...lawGoverment,
  ...rotatingImage,
  ...postingInformation,
  ...agriculturalDataInquiry,
  ...communicationConsulting,
  ...product,
  ...productType,
  ...jobRecruitment,
  ...strategicCooperation,
  ...companyProfile,
  ...companyQualification,
  ...companyCulture,
  ...accessLog,
  ...companyHonors
}
