import axios from 'axios'
import router from '../router/index'

const service = axios.create()

service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token && token !== 'undefined') {
      config.headers.token = token
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === '500' && (res.msg === 'token verify fail' || res.msg === 'token已经过期，请重新获取！')) {
      router.push({
        path: '/admLogin'
      })
    }
    return res
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
