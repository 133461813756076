export default [
  {
    path: '/admin',
    name: 'admin',
    redirect: '/rotatingImageList',
    // component: () => import('../../pages/admin/views/index.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/index'))
    }, 'admin')
  },
  {
    path: '/admLogin',
    name: 'admLogin',
    // component: () => import('../../pages/admin/views/login.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/login'))
    }, 'login')
  },
  {
    path: '/rotatingImageList',
    name: 'rotatingImageList',
    // component: () => import('../../pages/admin/views/rotatingImageList.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/rotatingImageList'))
    }, 'rotatingImageList')
  },
  {
    path: '/postingInformationList',
    name: 'postingInformationList',
    // component: () => import('../../pages/admin/views/postingInformationList.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/postingInformationList'))
    }, 'postingInformationList')
  },
  {
    path: '/lawGovermentList',
    name: 'lawGovermentList',
    // component: () => import('../../pages/admin/views/lawGovermentList.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/lawGovermentList'))
    }, 'lawGovermentList')
  },
  {
    path: '/agriculturalDataInquiryList',
    name: 'agriculturalDataInquiryList',
    // component: () => import('../../pages/admin/views/agriculturalDataInquiryList.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/agriculturalDataInquiryList'))
    }, 'agriculturalDataInquiryList')
  },
  {
    path: '/communicationConsultingList',
    name: 'communicationConsultingList',
    // component: () => import('../../pages/admin/views/communicationConsultingList.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/communicationConsultingList'))
    }, 'communicationConsultingList')
  },
  {
    path: '/productList',
    name: 'productList',
    // component: () => import('../../pages/admin/views/productList.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/productList'))
    }, 'productList')
  },
  {
    path: '/productTypeList',
    name: 'productTypeList',
    // component: () => import('../../pages/admin/views/productTypeList.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/productTypeList'))
    }, 'productTypeList')
  },
  {
    path: '/jobRecruitmentList',
    name: 'jobRecruitmentList',
    // component: () => import('../../pages/admin/views/jobRecruitmentList.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/jobRecruitmentList'))
    }, 'jobRecruitmentList')
  },
  {
    path: '/strategicCooperationIndex',
    name: 'strategicCooperationIndex',
    // component: () => import('../../pages/admin/views/strategicCooperationIndex.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/strategicCooperationIndex'))
    }, 'strategicCooperationIndex')
  },
  {
    path: '/companyProfileIndex',
    name: 'companyProfileIndex',
    // component: () => import('../../pages/admin/views/companyProfileIndex.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/companyProfileIndex'))
    }, 'companyProfileIndex')
  },
  {
    path: '/companyQualificationIndex',
    name: 'companyQualificationIndex',
    // component: () => import('../../pages/admin/views/companyQualificationIndex.vue')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/companyQualificationIndex'))
    }, 'companyQualificationIndex')
  },
  {
    path: '/companyCultureIndex',
    name: 'companyCultureIndex',
    // component: () => import('../../pages/admin/views/companyCultureIndex')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/companyCultureIndex'))
    }, 'companyCultureIndex')
  },
  {
    path: '/accessLogList',
    name: 'accessLogList',
    // component: () => import('../../pages/admin/views/companyCultureIndex')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/accessLogList'))
    }, 'accessLogList')
  },
  {
    path: '/companyHonorsIndex',
    name: 'companyHonorsIndex',
    // component: () => import('../../pages/admin/views/companyCultureIndex')
    component: r => require.ensure([], function (require) {
      r(require('@/pages/admin/views/companyHonorsIndex'))
    }, 'companyHonorsIndex')
  }
]
